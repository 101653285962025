import React from "react";

export function ContactInfo() {
    return (
        <div className="d-flex flex-column align-items-center">
            <h2 className="fs-1 mb-3  fw-bold">lilTriangle</h2>
            <p className="mb-5">lilTriangle.
                            4th E Cross Rd, MVS Arcade, 659, B.Channasandra Main Rd</p>
            
        </div>
    )
}

