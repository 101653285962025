import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container, FormGroup, Label, Input, Button, Col, Row } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { matchPath } from 'react-router';
import ProfileImage from '../../../src/assets/images/ProfileImage.png';
import StudentProfileHeader from '../../../src/assets/pages/StudentProfileHeader';

const StudentProfileDetails = (props) => {
    const location = useLocation();
    const navigate = useNavigate();


    const match = matchPath({
        path: "/student-profile-details/:schoolKey/:syncId/:kidId",
        exact: true,
        strict: false,
    }, location.pathname);

    const schoolKey = match ? match.params.schoolKey : null;
    const syncId = match ? match.params.syncId : null;
    const kidId = match ? match.params.kidId : null;



    const [data, setData] = useState('');
    const [selectedKid, setSelctedKid] = useState();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [invoiceList, setInvoiceList] = useState([]);

    const fetchSchool = async () => {
        setLoading(true);
        try {
            const response = await fetch("https://liltriangle.b4a.app/examVerification/getLandingPage", {
                method: "POST",
                body: JSON.stringify({ key: schoolKey }),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            });

            if (response.ok) {
                const result = await response.json();
                setData(result.result);
                setLoading(false);
                handlegetKidProfiles()
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            setError(error.message);
        }
    };


    const handlegetKidProfiles = async () => {
        setLoading(true)


        console.log("syncId VU:", syncId)

        try {
            const response = await fetch("https://liltriangle.b4a.app/examVerification/getStudentProfiles", {
                method: "POST",
                body: JSON.stringify({ syncId: syncId }),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            });

            if (response.ok) {
                const result = await response.json();

                if (result.result == 1) {
                    for (const kid of result.kids) {
                        if (kid.id == kidId) {
                            setSelctedKid(kid)
                            console.log("selectedKid", kid)
                            break
                        }

                    }

                    getInvoicesForKid()

                } else {

                }

            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            setError(error.message);
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    };

    const getInvoicesForKid = async () => {
        setLoading(true)


        console.log("syncId VU:", syncId)

        try {
            const response = await fetch("https://liltriangle.b4a.app/examVerification/getInvoicesForKidId", {
                method: "POST",
                body: JSON.stringify({ syncId: syncId, kidId: kidId }),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            });

            if (response.ok) {
                const result = await response.json();

                if (result.result == 1) {
                    setInvoiceList(result.invoices)
                    console.log(result.invoices);
                } else {

                }


            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            setError(error.message);
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        let localSyncId = localStorage.getItem("PWL-SYNC_ID")

        if (localSyncId == syncId) {
            fetchSchool();
        } else {
            //navigate to page not found
            navigate("/student-login/:schoolKey")
        }
    }, [schoolKey]);

    return (
        <div style={{ backgroundColor: '#FBFCFD', minHeight: '100vh' }}>
            {loading ? (
                <div style={{ marginTop: '2cm' }}>
                </div>
            ) : (
                <Container style={{ marginTop: '2cm', }}>
                    <div>
                        <center>
                            <StudentProfileHeader data={data} />
                        </center>
                        <center>
                            <Row>
                                <Col lg={4}>
                                    {selectedKid && <Card style={{ width: '5.2cm', height: '5.8cm', marginTop: '2.3cm'}} className='text-center'>
                                        <CardBody>
                                            <img src={selectedKid.photo ? selectedKid.photo : ProfileImage} alt="ProfileImage" style={{ width: '150px', height: '200px' }}></img>
                                            <p className='m-2'>{selectedKid.name}</p>
                                            <p>{selectedKid.classroomName}</p>
                                        </CardBody>
                                    </Card>}
                                </Col>
                                <Col lg={5} style={{marginTop: '1.5cm'}}>
                                    <b style={{ textDecoration: 'underline', display: 'flex', justifyContent: 'start', }} className='m-1'>All Invoices:</b>
                                    {invoiceList && invoiceList.length > 0 && invoiceList.map((invoice, key) =>
                                        <Card key={key} style={{ width: '15cm', height: '3cm' }} className='text-center m-2'>
                                            <CardBody style={{ position: 'relative', height: '100%' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'start'}}>
                                                    <p className='m-0'>{key + 1}.{invoice.title}</p>
                                                    <p className='m-0'>{"Invoice Number"}:{invoice.invoiceNumber}</p>
                                                    <p className='m-0'>{"Paid Amount"}:{invoice.paidAmount}</p>

                                                </div>

                                                <div style={{ position: 'absolute', bottom: '10px', right: '10px', display: 'flex', gap: '10px' }}>
                                                    <Button color="success" onClick={() => window.open("https://dashboard.liltriangle.com/invoice/" + invoice.objectId + "/1", '_blank')}>
                                                        Pay Now
                                                    </Button>
                                                    <Button color="primary" onClick={() => window.open("https://us-central1-liltriangleweb.cloudfunctions.net/html2pdf?url=https://dashboard.liltriangle.com/invoice/" + invoice.objectId + "/2", '_blank')}>
                                                        Download Receipt
                                                    </Button>
                                                </div>

                                            </CardBody>
                                        </Card>)}
                                </Col>


                            </Row>
                        </center>
                    </div>
                </Container>
            )}
        </div>
    );
};

export default StudentProfileDetails;
