import React, { useState, useEffect } from "react";
import { Container, Row, Col, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import lilLogo from '../../../src/assets/images/lilLogo.webp'

const StudentProfileHeader = ({ data }) => {


    return (
        <Container style={{ marginTop: '10px', marginBottom: '20px' }}>
            <Navbar expand="lg" className='fixed-top bg-body-tertiary shadow'>
                <Container>
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <div className="d-flex align-items-center">
                            <img src={lilLogo} alt='lilLogo' style={{ height: '7vh' }} className='me-2'></img>
                            lilTriangle
                        </div>
                        {data.schoolLogo && data.name && (
                            <div>
                                <img src={data.schoolLogo} style={{ height: '7vh' }} className='me-2'></img>
                                <strong className="text-center">{data.name}</strong>
                            </div>
                        )}
                    </div>
                </Container>
            </Navbar>

        </Container>
    )
}
export default StudentProfileHeader;