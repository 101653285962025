import React, { useState } from 'react';
import '../../../src/assets/css/Contact.css'
import { ContactInfo } from './ContactInfo';
import toastr from "toastr";
import 'toastr/build/toastr.min.css';

function Contact() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [date, setDate] = useState('');
    const [comments, setComments] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!firstName || !lastName || !email || !phoneNumber || !date || !comments) {
            console.log("All Fields are required");
            toastr.error("All fields are required");
            return;
        }
        console.log("Form Submitted successfully")
        toastr.success("Form submitted successfully");
        
    };


    return (
        <div className='contact-page'>
            <header className='mt-5'>
                <div className='container h-100 d-flex align-items-center justify-content-center'>
                    <h1 className='text-light'>Contact</h1>
                </div>
            </header>

            <div className='container my-5'>
                <div className='row'>
                    <div className='col-lg-6 d-flex align-items-center justify-content-center'>
                        <ContactInfo />
                    </div>
                    <div className='col-lg-6 d-flex justify-content-center'>
                        <form onSubmit={handleSubmit}>
                            <div className='row mb-3'>
                                <div className='col-md-6'>
                                    <label htmlFor='first-name'>First Name</label>
                                    <input
                                        type='text'
                                        id='first-name'
                                        className='form-control'
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <label htmlFor='last-name'>Last Name</label>
                                    <input
                                        type='text'
                                        id='last-name'
                                        className='form-control'
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-md-6'>
                                    <label htmlFor='email-address'>Email Address</label>
                                    <input
                                        type='email'
                                        id='email-address'
                                        className='form-control'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <label htmlFor='phone-number'>Phone Number</label>
                                    <input
                                        type='tel'
                                        id='phone-number'
                                        className='form-control'
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-md-6'>
                                    <label htmlFor='date'>Date</label>
                                    <input
                                        type='date'
                                        id='date'
                                        className='form-control'
                                        value={date}
                                        onChange={(e) => setDate(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='mb-4'>
                                <label htmlFor='comments'>Comments</label>
                                <textarea
                                    id='comments'
                                    className='form-control'
                                    value={comments}
                                    onChange={(e) => setComments(e.target.value)}
                                />
                            </div>
                            <button type='submit' className='btn btn-success btn-lg'>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
