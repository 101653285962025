import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { Skeleton } from "antd";

const TermsOfUse = () => {
  const location = useLocation();

  const match = matchPath({
    path: "/terms-of-use/:schoolKey",
    exact: true,
    strict: false,
  }, location.pathname);

  const schoolKey = match ? match.params.schoolKey : null;

  const [data, setData] = useState('');
  const [error, setError] = useState('');
  const [loding, setLoding] = useState(false)

  const fetchSchool = async () => {
    setLoding(true)
    try {
      const response = await fetch("https://liltriangle.b4a.app/examVerification/getLandingPage", {
        method: "POST",
        body: JSON.stringify({
          key: schoolKey
        }),
        headers: { "Content-type": "application/json; charset=UTF-8" },
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result);
        setData(result.result);
        setLoding(false)
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      setError(error.message);
    }
  };


  useEffect(() => {
    fetchSchool();
  }, [schoolKey]);


  return (
    <div>
      {loding ? (
        <div style={{ marginTop: '2cm'}}>
          <Skeleton paragraph={{ rows: 12 }} />
        </div>
      ) : (
        <Container style={{ marginTop: '20px', textAlign: 'center' }}>
          {data.schoolLogo && (
            <div>
              <img src={data.schoolLogo} className="mt-3" style={{ height: '10vh' }} alt="SchoolLogo" />
            </div>
          )}
          {data.name && (
            <strong className="text-center mb-5" style={{ color: '#ff0075', fontSize: '30px' }}>
              {data.name}
            </strong>
          )}


          <h1 className='mt-2'>Terms of Use</h1>
          <Card style={{ marginTop: '20px', textAlign: 'left' }}>
            <CardBody>
              {data.terms && (
                <div dangerouslySetInnerHTML={{ __html: data.terms }} />
              )}
            </CardBody>
          </Card>
        </Container>)}
    </div>
  );
};

export default TermsOfUse;
