import React from 'react';
import { Link, Routes, Route, useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Home from '../src/assets/pages/Home'
import Menu from '../src/assets/pages/Menu';
import About from '../src/assets/pages/About';
import Contact from '../src/assets/pages/Contact';
import ImageGallery from '../src/assets/pages/ImageGallery';
import SchoolDetails from '../src/assets/pages/SchoolDetails';
import TermsOfUse from '../src/assets/pages/TermsOfUse';
import Login from '../src/assets/pages/Login';
import StudentProfile from '../src/assets/pages/StudentProfile';
import StudentProfileDetails from '../src/assets/pages/StudentProfileDetails'
import Footer from '../src/assets/pages/footer';
import StudentProfileHeader from '../src/assets/pages/StudentProfileHeader';
import lilLogo from '../src/assets/images/lilLogo.webp';


function App() {
  const location = useLocation();
  const shouldHideNavAndFooter = location.pathname.includes('/terms-of-use') || location.pathname.includes('/student-login')  ||  location.pathname.includes('/student-profile') || location.pathname.includes('/student-profile-details') ;
  

  return (
    <div>
      {!shouldHideNavAndFooter && (
        <Navbar expand="lg" className='fixed-top bg-body-tertiary shadow'>
          <Container>



            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="d-flex align-items-center">
                <img src={lilLogo} alt='lilLogo' style={{ height: '7vh' }} className='me-2'></img>

                <Navbar.Brand>
                  <p className='navbar-brand text-pink fw-semibold'>
                    lilTriangle
                  </p>
                </Navbar.Brand>
              </div>
              <div className='d-flex justify-content-end'>
              <h5 className='mb-0'>Dashboard for Preschools</h5>
              </div>
            </div>





            {/* <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav className='me-auto justify-content-end w-100'>
                <Link to="/" className='nav-link active text-uppercase' style={{color:'red'}}>Home</Link>
                <Link to="/menu" className='nav-link text-uppercase' style={{color:'blue'}}>Reviews</Link>
                <Link to="/about" className='nav-link text-uppercase' style={{color:'yellow'}}>About</Link>
                <Link to="/contact" className='nav-link text-uppercase' style={{color:'purple'}}>Contact</Link>
              </Nav>
            </Navbar.Collapse> */}
          </Container>
        </Navbar>
      )}

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/menu' element={<Menu />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/gallery' element={<ImageGallery />} />
        <Route path='/:schoolKey' element={<SchoolDetails />} />
        <Route path='/terms-of-use/:schoolKey' element={<TermsOfUse />} />
        <Route path='/:schoolKey' element={<SchoolDetails />} />
        <Route path='/student-login/:schoolKey' element={<Login />} />
        <Route path='/student-profile/:schoolKey/:syncId' element= {<StudentProfile />} />
        <Route path='/student-profile-details/:schoolKey/:syncId/:kidId' element= {<StudentProfileDetails />} />


      </Routes>

      {!shouldHideNavAndFooter && <Footer />}
    </div>
  );
}

export default App;
