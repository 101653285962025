import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Gallery1 from '../../../src/assets/images/gallery1.jpg';

import Gallery2 from '../../../src/assets/images/gallery2.jpg';
import Gallery3 from '../../../src/assets/images/gallery3.jpg';
import Gallery4 from '../../../src/assets/images/gallery4.jpg';
import Gallery5 from '../../../src/assets/images/gallery5.jpg';
import Gallery6 from '../../../src/assets/images/gallery6.jpg';

const ImageGallery = () => {
  const [data, setData] = useState({});
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleImageClick = (schoolKey) => {
    navigate(`/${schoolKey}`);
  };

  useEffect(() => {
    fetchSchool();
  }, []);

  const getColor = (value) => value ? 'green' : 'red';

  const fetchSchool = async () => {
    try {
      const response = await fetch("https://liltriangle.b4a.app/examVerification/getLandingPage", {
        method: "POST",
        body: JSON.stringify({
          key: 'liltriangle-banglore',
          key:'little-winner',}),
        headers: { "Content-type": "application/json; charset=UTF-8" },
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Fetched data:', result);
        setData(result.result);
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Fetch error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container py-5">
      <h2 className="text-center fs-1 mb-5 text-uppercase fw-bold">Featured Centers</h2>
      <div className="row">
        <div className="col-md-2 px-2">
          <div className="my-3 image-container" onClick={() => handleImageClick('liltriangle-banglore')}>
            <img src={Gallery1} className="img-fluid" alt="Gallery 1" />
          </div>
        </div>
        <div className="col-md-2 px-2">
          <div className="my-3 image-container" onClick={() => handleImageClick('little-winners-play-school&daycare')}>
            <img src={Gallery2} className="img-fluid" alt="Gallery 2" />
          </div>
        </div>
        <div className="col-md-2 px-2">
          <div className="my-3 image-container" onClick={() => handleImageClick('Gallery3')}>
            <img src={Gallery3} className="img-fluid" alt="Gallery 3" />
          </div>
        </div>
        <div className="col-md-2 px-2">
          <div className="my-3 image-container" onClick={() => handleImageClick('Gallery1')}>
            <img src={Gallery1} className="img-fluid" alt="Gallery 4" />
          </div>
        </div>
        <div className="col-md-2 px-2">
          <div className="my-3 image-container" onClick={() => handleImageClick('Gallery5')}>
            <img src={Gallery3} className="img-fluid" alt="Gallery 5" />
          </div>
        </div>
        <div className="col-md-2 px-2">
          <div className="my-3 image-container" onClick={() => handleImageClick('Gallery6')}>
            <img src={Gallery2} className="img-fluid" alt="Gallery 6" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageGallery;
