import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container, FormGroup, Label, Input, Button, Col, Row } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { matchPath } from 'react-router';
import StudentProfileHeader from './StudentProfileHeader';
import StudentLoginImage from '../../../src/assets/images/StudentImage22.png';

const Login = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const match = matchPath({
        path: "/student-login/:schoolKey",
        exact: true,
        strict: false,
    }, location.pathname);

    const schoolKey = match ? match.params.schoolKey : null;

    const [schoolData, setSchoolData] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [verificationState, setVerificationState] = useState(null);
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(30);
    const [message, setMessage] = useState('');
    const [resendDisabled, setResendDisabled] = useState(false);

    useEffect(() => {
        let countdown;
        if (timer > 0 && resendDisabled) {
            countdown = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
        } else if (timer === 0) {
            setResendDisabled(false);
            setMessage('');
        }
        return () => clearInterval(countdown);
    }, [timer, resendDisabled]);

    function generateUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
                v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    const [syncId, setSyncId] = useState("PWL-" + generateUUID());

    const handleVerifyNoEmail = (input) => {
        const phonePattern = /^[0-9]{10,16}$/;
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!input) {
            return "Phone number or email is required";
        }
        if (phonePattern.test(input)) {
            if (input.length < 10 || input.length > 16) {
                return "You have entered an invalid phone number!";
            }
        } else if (!emailPattern.test(input)) {
            return "You have entered an invalid phone number or email address!";
        }
        return "";
    };

    const handleVerifyNumberEmail = async () => {
        setButtonLoading(true);
        const phoneNumberError = handleVerifyNoEmail(phoneNumber);
        if (phoneNumberError) {
            setPhoneNumberError(phoneNumberError);
            return;
        }

        setPhoneNumberError('');

        try {
            const response = await fetch("https://liltriangle.b4a.app/examVerification/verifyUser", {
                method: "POST",
                body: JSON.stringify({ username: phoneNumber, syncId: syncId, schoolId: schoolData.schoolId }),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            });

            if (response.ok) {
                const result = await response.json();

                if (result.result === 1) {
                    setVerificationState('otp');
                } else {
                    setPhoneNumberError(result.result === 0 ? "Wrong number" : "Student does not exist");
                }
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setButtonLoading(false);
        }
    };

    const handleReRequestOtp = async () => {
        setLoading(true);
        setMessage('');

        try {
            const response = await fetch("https://liltriangle.b4a.app/examVerification/requestOTP", {
                method: "POST",
                body: JSON.stringify({ username: phoneNumber, syncId: syncId }),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            });

            if (response.ok) {
                const result = await response.json();

                if (result.result === 1) {
                    setMessage('Check text messages/email for your OTP');
                    setResendDisabled(true);
                    setTimer(30);
                } else {
                    setMessage('Please try again later');
                    setResendDisabled(false);
                }
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleOtpSubmit = async () => {
        setLoading(true);
        setButtonLoading(true);

        try {
            const response = await fetch("https://liltriangle.b4a.app/examVerification/verifyOTP", {
                method: "POST",
                body: JSON.stringify({ userOTP: otp, username: phoneNumber, syncId: syncId }),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            });

            if (response.ok) {
                const result = await response.json();

                if (result.result === 1) {
                    if (schoolData && schoolData.schoolKey && syncId) {
                        navigate("/student-profile/" + schoolData.schoolKey + "/" + syncId);
                    }
                }
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setButtonLoading(false);
        }
    };

    const fetchSchool = async () => {
        setLoading(true);
        localStorage.setItem("PWL-SYNC_ID", syncId);
        try {
            const response = await fetch("https://liltriangle.b4a.app/examVerification/getLandingPage", {
                method: "POST",
                body: JSON.stringify({ key: schoolKey }),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            });

            if (response.ok) {
                const result = await response.json();
                setSchoolData(result.result);
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSchool();
    }, [schoolKey]);

    return (
        <React.Fragment>
            <div style={{ backgroundColor: '#FBFCFD', minHeight: '90vh' }}>
                <Container style={{ marginTop: '2cm', textAlign: 'center' }}>
                    <center>
                        <StudentProfileHeader data={schoolData} />
                    </center>
                    <Container>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3cm' }}>
                            <Row>
                                <Card className='m-2 shadow p-2 overflow-hidden' style={{ width: '12cm' }}>
                                    <div>
                                        <div>
                                            <Row>
                                                <Col md="7">
                                                    <div className="m-2">
                                                        <h5>Welcome Back!</h5>
                                                        <p className='m-0'>Login to continue.</p>
                                                    </div>
                                                </Col>
                                                <Col md="5" className="align-self-end">
                                                    <img src={StudentLoginImage} alt="" className="img-fluid" />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <CardBody>
                                        {verificationState === null ? (
                                            <FormGroup>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                                                    <Label style={{ color: '#000000' }}>Email or Phone Number <span className="text-danger">*</span></Label>
                                                    <Input type="text" placeholder="Enter Phone Number or Email" value={phoneNumber} onChange={(e) => {
                                                        setPhoneNumber(e.target.value);
                                                        setPhoneNumberError(handleVerifyNoEmail(e.target.value));
                                                    }} />
                                                    {phoneNumberError && <div className="text-danger">{phoneNumberError}</div>}
                                                </div>
                                            </FormGroup>
                                        ) : verificationState === 'otp' ? (
                                            <>
                                                <FormGroup>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                        <Label className='m-1'>Email/Phone Number</Label>
                                                        <Input type="text" value={phoneNumber} readOnly />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                        <Label className='m-1'>Enter OTP: <span className="text-danger">*</span></Label>
                                                        <Input type="text" value={otp} onChange={(e) => setOtp(e.target.value)} style={{ width: '100%' }} />
                                                    </div>
                                                </FormGroup>
                                                <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'end' }}>
                                                    <p className="text-muted">Didn't receive the OTP?</p>
                                                    <a href="#" style={{ textDecoration: 'underline', color: 'blue', marginLeft: '5px' }} onClick={resendDisabled ? null : handleReRequestOtp}>
                                                        Resend OTP {resendDisabled ? `in ${timer} seconds` : ''}
                                                    </a>
                                                </div>
                                                {message && <p className="text-success">{message}</p>}
                                                {error && <p className="text-danger">{error}</p>}
                                            </>
                                        ) : null}

                                        {verificationState === 'otp' ? (
                                            <Button
                                                style={{
                                                    background: '#fe1386',
                                                    borderRadius: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                                className='m-3'
                                                onClick={handleOtpSubmit}
                                                disabled={buttonLoading}
                                            >
                                                {buttonLoading ? (
                                                    <i className="fas fa-spinner fa-pulse"></i>
                                                ) : (
                                                    <>
                                                        Submit
                                                        <i className="fa-solid fa-chevron-right"
                                                            style={{
                                                                color: '#fe1386',
                                                                backgroundColor: 'white',
                                                                borderRadius: '50%',
                                                                padding: '5px',
                                                                marginLeft: '10px'
                                                            }}
                                                        ></i>
                                                    </>
                                                )}
                                            </Button>
                                        ) : (
                                            <Button
                                                style={{
                                                    background: '#fe1386',
                                                    borderRadius: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                                className='m-3'
                                                onClick={handleVerifyNumberEmail}
                                                disabled={buttonLoading}
                                            >
                                                {buttonLoading ? (
                                                    <i className="fas fa-spinner fa-pulse"></i>
                                                ) : (
                                                    <>
                                                        Verify
                                                        <i className="fa-solid fa-chevron-right"
                                                            style={{
                                                                color: '#fe1386',
                                                                backgroundColor: 'white',
                                                                borderRadius: '50%',
                                                                padding: '5px',
                                                                marginLeft: '10px'
                                                            }}
                                                        ></i>
                                                    </>
                                                )}
                                            </Button>
                                        )}
                                    </CardBody>
                                </Card>
                                {/* <Card className="overflow-hidden">
                                    <div className="bg-soft-primary">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-primary p-4">
                                                    <h5 className="text-primary">Welcome Back !</h5>
                                                    <p>Sign in to continue to lilTriangle.</p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                <img src={profile} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div>
                                            <Link to="/">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img
                                                            src={logo}
                                                            alt=""
                                                            className="rounded-circle"
                                                            height="34"
                                                        />
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="p-2">
                                            <AvForm className="form-horizontal">
                                                <div className="form-group">
                                                    <AvField
                                                        name="email"
                                                        label="Email"
                                                        value=""
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        type="email"
                                                        required
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <AvField
                                                        name="password"
                                                        label="Password"
                                                        value=""
                                                        type="password"
                                                        required
                                                        placeholder="Enter Password"
                                                    />
                                                </div>

                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="customControlInline"
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="customControlInline"
                                                    >
                                                        Remember me
                                                    </label>
                                                </div>

                                                <div className="mt-3">
                                                    <button
                                                        className="btn btn-primary btn-block waves-effect waves-light"
                                                        type="submit"
                                                    >
                                                        Log In
                                                    </button>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <Link to="/pages-forgot-pwd" className="text-muted">
                                                        <i className="mdi mdi-lock mr-1"></i> Forgot your
                                                        password?
                                                    </Link>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card> */}
                            </Row>
                        </div>
                    </Container>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Login;

