import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container, Col, Row } from 'reactstrap';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { matchPath } from 'react-router';
import ProfileImage from '../../../src/assets/images/ProfileImage.png';
import StudentProfileHeader from '../../../src/assets/pages/StudentProfileHeader';

const StudentProfile = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    const match = matchPath({
        path: "/student-profile/:schoolKey/:syncId",
        exact: true,
        strict: false,
    }, location.pathname);

    const schoolKey = match ? match.params.schoolKey : null;
    const syncId = match ? match.params.syncId : null;

    const [schoolData, setSchoolData] = useState('');
    const [kidList, setKidList] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchSchool = async () => {
        setLoading(true);
        try {
            const response = await fetch("https://liltriangle.b4a.app/examVerification/getLandingPage", {
                method: "POST",
                body: JSON.stringify({ key: schoolKey }),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            });

            if (response.ok) {
                const result = await response.json();
                setSchoolData(result.result);
                setLoading(false);
                handlegetKidProfiles()
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const handlegetKidProfiles = async () => {
        setLoading(true)
        
        
        console.log("syncId VU:",syncId)

        try {
            const response = await fetch("https://liltriangle.b4a.app/examVerification/getStudentProfiles", {
                method: "POST",
                body: JSON.stringify({syncId:syncId }),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            });

            if (response.ok) {
                const result = await response.json();

                if(result.result==1){
                    setKidList(result.kids); 
                }else{
                    
                }
                
                console.log(result);
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            setError(error.message);
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {

        let localSyncId = localStorage.getItem("PWL-SYNC_ID")

        if(localSyncId==syncId){
            fetchSchool();
        }else{
            navigate("/page-not-found/")
        }
        
    }, [schoolKey]);

    return (
        <div style={{ backgroundColor: '#FBFCFD', minHeight: '100vh' }}>
            {loading ? (
                <div style={{ marginTop: '2cm' }}>
                </div>
            ) : (
                <Container style={{ marginTop: '2cm' }}>
                    <center>
                        <StudentProfileHeader data={schoolData} />
                    </center>
                    <div>
                        <center>
                            <Row>
                                <Col lg={6}>
                                    {kidList && kidList.map((kid)=>
                                        <Link key={kid.id} to={`/student-profile-details/${schoolData.schoolKey}/${syncId}/${kid.id}`}
                                            style={{ textDecoration: 'none' }}>
                                            <h3>Click on Student Details</h3>
                                            <Card
                                                style={{ width: '5.2cm', height: '5.8cm', cursor: 'pointer' }}
                                                className='text-center'
                                            >
                                                <CardBody>
                                                    <img
                                                        src={kid.photo?kid.photo:ProfileImage}
                                                        alt="ProfileImage"
                                                        style={{ width: '150px', height: '5.2cm' }}
                                                    />
                                                    <p className='m-2'>{kid.name}</p>
                                                    <p>{kid.classroomName}</p>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    )}
                                </Col>
                               
                            </Row>
                        </center>
                    </div>
                </Container>
            )}
        </div>
    );
};

export default StudentProfile;
